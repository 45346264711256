<template>

    

    <div style="padding:20px;">


        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="">
                    <h1 >Status dos Sistemas x Modulos</h1>
                </div>

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h2>Ficha Digital</h2>
                    <div style="margin-top:20px;padding-left:20px;">
                        
                        <div style="margin-top:20px;">
                            <h3>Web check in</h3>
                            <h5 style="color:green;">Disponivel</h5>
                            <h5 style="color:#438ed7;">Ultima Atualização: 11/06/2024</h5>
                            <h5 style="color:#438ed7;">Integração Disponivel: Hits, Totvs, ADMH, Erbon</h5>
                            <div style="padding-left:20px;">
                                <p>Pronto pra uso.</p>
                                <p>Existe um projeto para ter uma versão do web check in em tela unica.</p>
                            </div>
                        </div>

                        <div style="margin-top:20px;">
                            <h3>Check Out</h3>
                            <h5 style="color:green;">Disponivel</h5>
                            <h5 style="color:#438ed7;">Ultima Atualização: 11/06/2024</h5>
                            <h5 style="color:#438ed7;">Integração Disponivel: Hits, Totvs, ADMH</h5>
                            <div style="padding-left:20px;">
                                <p>Testado em Ambiente Hits mas nunca utilizado por Cliente</p>
                                <p>Testado em Ambiente Admh primeiro cliente Jardim Atlantico</p>
                                <p>Não testado em ambiente Totvs</p>
                            </div>
                        </div>

                        <div style="margin-top:20px;">
                            <h3>Check In Pay</h3>
                            <h5 style="color:green;">Disponivel</h5>
                            <h5 style="color:#438ed7;">Ultima Atualização: 11/06/2024</h5>
                            <h5 style="color:#438ed7;">Integração Disponivel: Hits, Totvs, ADMH</h5>
                            <div style="padding-left:20px;">
                                <p>Testado em Ambiente Hits mas nunca utilizado por Cliente</p>
                                <p>Testado em Ambiente Admh primeiro cliente Jardim Atlantico</p>
                                <p>Não testado em ambiente Totvs</p>
                            </div>
                        </div>

                        <div style="margin-top:20px;">
                            <h3>Web Check in Express - Modo Site do Cliente</h3>
                            <h5 style="color:green;">Disponivel</h5>
                            <h5 style="color:#438ed7;">Ultima Atualização: 11/06/2024</h5>
                            <h5 style="color:#438ed7;">Integração Disponivel: Hits, Totvs, ADMH, Erbon</h5>
                            <div style="padding-left:20px;">
                                <p>Pronto pra uso.</p>
                            </div>
                        </div>

                        <div style="margin-top:20px;">
                            <h3>Web Check in Express - Modo Portaria</h3>
                            <h5 style="color:green;">Disponivel</h5>
                            <h5 style="color:#438ed7;">Ultima Atualização: 11/06/2024</h5>
                            <h5 style="color:#438ed7;">Integração Disponivel: Hits, Totvs, ADMH, Erbon</h5>
                            <div style="padding-left:20px;">
                                <p>Pronto pra uso.</p>
                            </div>
                        </div>

                        <div style="margin-top:20px;">
                            <h3>Contrato no web Check in</h3>
                            <h5 style="color:green;">Disponivel</h5>
                            <h5 style="color:#438ed7;">Ultima Atualização: 11/06/2024</h5>
                            <h5 style="color:#438ed7;">Integração Disponivel: Hits, Totvs, ADMH, Erbon</h5>
                            <div style="padding-left:20px;">
                                <p>Pronto pra uso.</p>
                            </div>
                        </div>

                        <div style="margin-top:20px;">
                            <h3>Envio de senha dos quartos</h3>
                            <h5 style="color:green;">Disponivel</h5>
                            <h5 style="color:#438ed7;">Ultima Atualização: 11/06/2024</h5>
                            <h5 style="color:#438ed7;">Integração Disponivel: Hits, Totvs, ADMH, Erbon</h5>
                            <div style="padding-left:20px;">
                                <p>Pronto pra uso.</p>
                            </div>
                        </div>


                        <div style="margin-top:20px;">
                            <h3>Captura de hospedes Avulso</h3>
                            <h5 style="color:green;">Disponivel</h5>
                            <h5 style="color:#438ed7;">Ultima Atualização: 11/06/2024</h5>
                            <h5 style="color:#438ed7;">Integração Disponivel: Hits</h5>
                            <div style="padding-left:20px;">
                                <p>Pronto pra uso.</p>
                            </div>
                        </div>

                        <div style="margin-top:20px;">
                            <h3>Ferramenta de Template</h3>
                            <h5 style="color:green;">Disponivel</h5>
                            <h5 style="color:#438ed7;">Ultima Atualização: 11/06/2024</h5>
                            <h5 style="color:#438ed7;">Integração Disponivel: Hits, Totvs, ADMH, Erbon</h5>
                            <div style="padding-left:20px;">
                                <p>Pronto pra uso.</p>
                            </div>
                        </div>

                    </div>
                </div>

                <hr style="margin-top:50px;margin-bottom:50px;">

                <div style="margin-top:20px;margin-left:30px;">
                    <h2>Cardapio Digital</h2>
                    <div style="margin-top:20px;padding-left:20px;">
                        <p>dasdadad</p>
                    </div>
                </div>

                <hr style="margin-top:50px;margin-bottom:50px;">

                <div style="margin-top:20px;margin-left:30px;">
                    <h2>FD Guest</h2>
                    <div style="margin-top:20px;padding-left:20px;">
                        <p>dasdadad</p>
                    </div>
                </div>

                <hr style="margin-top:50px;margin-bottom:50px;">

               


                  <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h2>Central de Controle</h2>
                    <div style="margin-top:20px;padding-left:20px;">
                        
                        <div style="margin-top:20px;">
                            <h3>Contrato</h3>
                            <h5 style="color:#438ed7;">Ultima Atualização: 11/06/2024</h5>
                            <h5 style="color:red;">Indisponivel</h5>
                            <div style="padding-left:20px;">
                                <p>Aplicação já se encontra disponivel em versão BETA, encontra-se sem layout boniyo, sem revisão de contrato, sem layout de email bem definido e sem processo bem definido.</p>
                                <p>Na realidade de hoje, adiciona-se o cliente, vincula-se o codigo da proposta, vincula-se os sistemas contratados e em seguinda o sistema dispara o email para o cliente para coleta de assinatura no contrato previamente cadastrado.</p>
                                <p>Apos a assinatura do contrato o sistema envia email para o cliente e para economy contendo o contrato assinado.</p>
                                <b>Necessidades:</b>
                                <p>- Definição real do Processo</p>
                                <p>- Revisão do Contrato</p>
                                <p>- Layout de Interface Para o Cliente Final</p>
                                <p>- Layout de Email</p>
                                <p>- Texto comercial do Email</p>
                            </div>
                        </div>

                        

                    </div>
                </div>
                






            </v-card-text>
               
        </v-card>


    </div>

    
</template>

<script>

export default {
    name:'Robos',
    components: {
    },
    data(){
        return{
            
        }
    },
    watch: {
        
    },
    methods:{
            
    },

    
    async mounted(){

    }
    
}
</script>

<style>
    .linhaFormulario{
        display: flex;
        flex-direction: row;
        /* background-color:red; */
        padding-left: 10%;
        padding-right: 10%;
    }

    .celularCampoForm{
        width:25%;
        padding:10px;
        /* background-color: green; */
    }

    .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
        color: rgba(0, 0, 0);
    }

</style>